<template>


	<h2 class="text-info">HEADER</h2>

  </template>
  
  <script>

  
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  </style>